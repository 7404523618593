<template >
  <div class="body">
	  <navTop></navTop>
    <div class="wrap">
      <div class="wrap-left">
        <menuLeft :active="menuIndex"></menuLeft>
      </div>
      <div class="wrap-right">
        <div class="wrap-right-header">
          <div class="wrh-left">
            <div class="wrh-left-lv">{{ $t("customDesign.designNumber") }}</div>
            <div class="wrh-left-number">{{ userInfo.fraction }}</div>
            <div class="wrh-left-score">({{ $t("customDesign.designTip") }})</div>
            <div class="wrh-left-code"><el-link @click="toRecharge()" :underline="false" style="color:#fff;font-size: 16px;"><i class="el-icon-c-scale-to-original"></i>{{ $t("customDesign.clickRecharge") }}</el-link></div>
          </div>
          <div class="wrh-right">
            
          </div>
        </div>
        <div class="wrap-right-body">
          <table class="tab-header" border="0">
           <tr>
			   <td>
			     {{ $t('userCenter.goodsInfo') }}
			   </td>
			   <td>{{ $t('userCenter.val') }}</td>
			   <td>{{ $t('userCenter.price') }}</td>
			   <td>{{ $t('userCenter.number') }}</td>
		   </tr>
            <tr v-for="(item,index) in goods" >
				<td>{{$lang(item.goodsInfo,"goodsInfo")}}</td>
				<td style="font-size: 24px;font-weight: 600;color:brown">{{item.val}}</td>
				<td>{{item.price}}</td>
				<td>{{item.number}}</td>
			</tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import menuLeft from "@/components/MenuLeft";
import navTop from "@/components/navTop";
export default {
	components: {menuLeft,navTop},
	data() {
		return {
			menuIndex:'2',
			userInfo:{
				dpNumber: {
					designPointZhCn:"当前剩余设计点",
					designPointEnUs:"Current remaining design points",
				},
				fraction: 897,
				couponValue: 810,
			},
			goods:[
			
			]
			
		}
	},
	methods: {
		toRecharge(){
			this.$router.push("customdesign/designrecharge")
		}
	}
}
</script>
<style  scoped>
.body {}

.wrap {
  margin: 0 auto;
  width: 1200px;
  display: flex;
  justify-content: space-between;
}

.wrap-left {
  font-size: 14px;
  width: 20%;
  min-height: 800px;
  background-color: #fff;
  padding: 20px 15px 40px;
}

.wrap-right {
  width: 78%;
  min-height: 800px;
  background-color: #fff;
}

.wrap-right-header {
  display: flex;
  justify-content: space-between;
  height: 200px;
  background-color: #555;
  color: #fff;
}

.wrh-left {
  padding: 20px;
  line-height: 40px;
  width: 50%;
  text-align: center;
}

.wrh-left-lv {
  font-size: 14px;
  color: yellow;
}

.wrh-left-number {
  font-size: 40px;
  color: #5DAF34;
  font-weight: 800;
}

.wrh-left-score {
  color: #ccc;

}

.wrh-right {
  padding: 30px;
  line-height: 40px;
  width: 50%;
  text-align: center;
 
}

.wrh-right-number {
  margin-top: 20px;
  font-size: 40px;
  color: darkcyan;
  font-weight: 800;
}

.wrh-right-coupon {
  color: #ccc;
  margin-bottom: 5px;
}

.tab-header {
  width: 100%;
}

.tab-header tr td {
  font-size: 14px;
  text-align: center;
  padding: 30px 0px 0px;
  color: #888;
}

.tab-body {

  width: 100%;
}

.tab-body tr td {
  font-size: 14px;
  padding: 40px 0px;
  text-align: center;
}
</style>
